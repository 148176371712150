// src/components/Dashboard/Ecommerce/ProductDetailsPage/ProductConnections.jsx

import React, { useEffect, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Define possible listing statuses and their corresponding UI indicators
const STATUS_CONFIG = {
  draft: { color: 'bg-yellow-500', label: 'Draft' },
  active: { color: 'bg-green-500', label: 'Active' },
  inactive: { color: 'bg-red-500', label: 'Inactive' },
  disabled: { color: 'bg-gray-500', label: 'Disabled' },
  relist: { color: 'bg-blue-500', label: 'Relist' },
};

const ProductConnections = ({
  selectedConnections,
  setSelectedConnections,
  inventoryRecord,
  darkMode,
  setFormData,
  formData, // Access formData for pending changes
  isEditMode, // Indicates if in edit mode
  initialListToChannels, // New prop
  openModal, // New prop: function to open modal
}) => {
  const { connections: allConnections, loading: connectionsLoading, error: connectionsError } =
    useSelector((state) => state.connection);

  // Error state for handling connection loading errors
  const [localError, setLocalError] = React.useState(null);

  // Effect to handle connection loading errors
  useEffect(() => {
    if (connectionsError) {
      setLocalError('Failed to load connections. Please try again later.');
      toast.error('Failed to load connections.', { toastId: 'connections-error' });
    } else {
      setLocalError(null);
    }
  }, [connectionsError]);

  // Function to get current listingStatus from inventoryRecord
  const getCurrentStatus = (connectionId) => {
    const channel = inventoryRecord?.listToChannels?.find(
      (ch) => ch.connectionId === connectionId
    );
    return channel?.listingStatus || 'disabled';
  };

  // Function to get pending listingStatus from formData
  const getPendingStatus = (connectionId) => {
    const channel = formData?.listToChannels?.find(
      (ch) => ch.connectionId === connectionId
    );
    return channel?.listingStatus || getCurrentStatus(connectionId);
  };

  // Function to determine the status indicator based on listingStatus
  const getStatusIndicator = (listingStatus) => {
    const config = STATUS_CONFIG[listingStatus] || STATUS_CONFIG['disabled'];
    return (
      <span
        className={`h-4 w-4 ${config.color} rounded-full`}
        title={config.label}
      ></span>
    );
  };

  // Function to get label based on listingStatus
  const getStatusLabel = (listingStatus) => {
    const config = STATUS_CONFIG[listingStatus] || STATUS_CONFIG['disabled'];
    return config.label;
  };

  // Graceful degradation: Show message if no connections are available
  if (connectionsLoading) {
    return (
      <div
        className={`p-6 rounded-lg shadow-md w-full ${darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'
          }`}
      >
        <p>Loading connections...</p>
      </div>
    );
  }

  if (localError) {
    return (
      <div
        className={`p-6 rounded-lg shadow-md w-full ${darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'
          }`}
      >
        <p className="text-red-500">{localError}</p>
      </div>
    );
  }

  return (
    <div
      className={`p-6 rounded-lg shadow-md w-full ${darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'
        }`}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Sales Channels</h3>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center text-gray-500 hover:text-gray-700 dark:hover:text-gray-400">
              <DotsHorizontalIcon className="w-5 h-5" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`absolute right-0 mt-2 w-56 ${darkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-lg ring-1 ring-black ring-opacity-5 rounded-md focus:outline-none`}
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={openModal} // Use the openModal function from props
                      className={`block px-4 py-2 text-sm ${active
                        ? darkMode
                          ? 'bg-gray-700'
                          : 'bg-gray-100'
                        : ''
                        } text-gray-700 dark:text-gray-100`}
                    >
                      Manage Sales Channels
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {allConnections.length === 0 ? (
        <p>No sales channels available.</p>
      ) : (
        <ul className="space-y-4">
          {allConnections.map((conn) => {
            const currentStatus = getCurrentStatus(conn.id);
            const pendingStatus = getPendingStatus(conn.id);
            const hasPendingChange = currentStatus !== pendingStatus;

            return (
              <li
                key={conn.id}
                className="flex justify-between items-center py-2"
              >
                <div className="flex items-center space-x-2">
                  {getStatusIndicator(currentStatus)}
                  <span className="text-sm">
                    {conn.accountName} ({conn.platform})
                  </span>
                  {hasPendingChange && (
                    <span className="ml-2 px-2 py-0.5 text-xs bg-blue-100 text-blue-800 rounded">
                      Pending: {getStatusLabel(pendingStatus)}
                    </span>
                  )}
                </div>
                {hasPendingChange && (
                  <span
                    className={`text-xs px-2 py-1 rounded-full ${STATUS_CONFIG[pendingStatus]?.color || 'bg-yellow-500'
                      } text-white`}
                  >
                    {getStatusLabel(pendingStatus)}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ProductConnections;
