// src/pages/ProductDetailsPage.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import {
  getInventoryItem,
  updateItem,
  addInventoryItem,
  clearItemDetails,
} from '../../features/inventory/inventorySlice';
import { fetchConnections } from '../../features/connections/connectionSlice';
import Spinner from '../../components/Spinner';
import ProductBasicDetails from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductBasicDetails';
import ProductOrganization from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductOrganization';
import ProductConnections from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductConnections';
import ProductMediaSection from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductMediaSection';
import ManageConnectionsModal from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ManageConnectionsModal'; // Import Modal
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { itemDetails, loading } = useSelector((state) => state.inventory);
  const { connections, loading: connectionsLoading, error: connectionsError } = useSelector((state) => state.connection);

  // Dark mode state
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') === 'dark'
  );
  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'dark');
    };
    window.addEventListener('themeChange', handleThemeChange);
    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  // Initialize formData with default structure
  const [formData, setFormData] = useState({
    basicDetails: {
      brand: '',
      model: '',
      condition: 'Select Condition',
      quantity: 1,
      item_description: '',
      dimensions: { length: '', width: '', height: '', unit: 'INCH' },
      weight: { value: '', unit: 'POUND' },
      shippingMethods: ['UPSGround'],
      price: 0,
      additional_info: '',
      bestOffer: false,
      autoAcceptPrice: 0,
      autoDeclinePrice: 0,
      acceptsReturns: true,
    },
    category: '',
    listToChannels: [],
    photographyStatus: 'pending', // New field for new items
    recordCompletionStatus: 'draft', // New field for new items
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedConnections, setSelectedConnections] = useState([]);

  const isEditMode = Boolean(productId);
  const pageTitle = isEditMode ? 'Edit Product' : 'Create New Product';

  // New state to preserve initial listToChannels
  const [initialListToChannels, setInitialListToChannels] = useState([]);

  // **Modal State**
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal

  // Clear item details if creating a new product
  useEffect(() => {
    if (!isEditMode) {
      dispatch(clearItemDetails());
    }
  }, [dispatch, isEditMode]);

  // Fetch inventory item and connections on mount or when productId changes
  useEffect(() => {
    if (isEditMode) {
      dispatch(getInventoryItem(productId));
    }
    dispatch(fetchConnections());
  }, [dispatch, productId, isEditMode]);

  // Populate formData and initialListToChannels when itemDetails are loaded (Edit Mode)
  useEffect(() => {
    if (itemDetails) {
      setFormData((prevData) => ({
        ...prevData,
        basicDetails: {
          brand: itemDetails.brand || '',
          model: itemDetails.model || '',
          item_description: itemDetails.item_description || '',
          condition: itemDetails.condition || 'Select Condition',
          quantity: itemDetails.quantity || 1,
          dimensions:
            itemDetails.dimensions || {
              length: '',
              width: '',
              height: '',
              unit: 'INCH',
            },
          weight: itemDetails.weight || { value: '', unit: 'POUND' },
          shippingMethods: itemDetails.shippingMethods || ['UPSGround'],
          additional_info: itemDetails.additional_info || '',
          price: itemDetails.price || 0,
          bestOffer:
            itemDetails?.ebayDetails?.bestOfferTerms?.bestOfferEnabled ||
            false,
          autoAcceptPrice:
            itemDetails?.ebayDetails?.bestOfferTerms?.autoAcceptPrice?.value ||
            0,
          autoDeclinePrice:
            itemDetails?.ebayDetails?.bestOfferTerms?.autoDeclinePrice?.value ||
            0,
          acceptsReturns: itemDetails?.acceptsReturns || true,
        },
        category: itemDetails.category || '',
        listToChannels: itemDetails.listToChannels || [],
        photographyStatus: itemDetails.photographyStatus || 'pending', // Set existing or default
        recordCompletionStatus: itemDetails.recordCompletionStatus || 'draft', // Set existing or default
      }));

      // Preserve the initial listToChannels
      setInitialListToChannels(itemDetails.listToChannels || []);

      setSelectedConnections(connections.map((conn) => conn.id)); // Include all connections
    }
  }, [itemDetails, connections]);

  // Initialize listToChannels for new records based on listToByDefault
  useEffect(() => {
    if (
      !isEditMode &&
      connections.length > 0 &&
      formData.listToChannels.length === 0
    ) {
      const defaultConnections = connections
        .filter((conn) => conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          connectionId: conn.id,
          listingStatus: 'draft',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        }));

      const nonDefaultConnections = connections
        .filter((conn) => !conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          connectionId: conn.id,
          listingStatus: 'disabled',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        }));

      setSelectedConnections(connections.map((conn) => conn.id)); // Include all connections

      setFormData((prevData) => ({
        ...prevData,
        listToChannels: [...defaultConnections, ...nonDefaultConnections],
        photographyStatus: 'pending', // Ensure it's set to 'pending'
        recordCompletionStatus: 'draft', // Ensure it's set to 'draft'
      }));
    }
  }, [
    connections,
    isEditMode,
    formData.listToChannels.length,
  ]);

  // Merge existing connections with any new connections and preserve listingStatus (Edit Mode)
  useEffect(() => {
    if (isEditMode && connections.length > 0 && formData.listToChannels.length > 0) {
      const existingConnectionIds = formData.listToChannels.map(
        (channel) => channel.connectionId
      );
      const newConnections = connections.filter(
        (conn) => !existingConnectionIds.includes(conn.id)
      );

      const newListToChannels = newConnections.map((conn) => ({
        platform: conn.platform,
        connectionId: conn.id,
        listingStatus: 'disabled',
        marketplaceId: conn.marketplaceId || 'EBAY_US',
      }));

      if (newListToChannels.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          listToChannels: [...prevData.listToChannels, ...newListToChannels],
        }));
      }
    }
  }, [isEditMode, connections, formData.listToChannels]);

  // Handle changes in basic details
  const handleBasicDetailsChange = (updatedBasicDetails) => {
    setFormData((prevData) => ({
      ...prevData,
      basicDetails: updatedBasicDetails,
    }));
  };

  // Handle category change
  const handleCategoryChange = (newCategory) => {
    setFormData((prevData) => ({
      ...prevData,
      category: newCategory,
    }));
  };

  // Validate form based on mode (draft or completed)
  const handleFormValidation = (mode) => {
    const errors = {};

    const capitalizeFieldName = (field) =>
      field.replace('.', ' ').replace(/^\w/, (c) => c.toUpperCase());

    const draftRequiredFields = ['brand', 'condition'];
    const completedRequiredFields = [
      'brand',
      'model',
      'condition',
      'quantity',
      'dimensions.length',
      'dimensions.width',
      'dimensions.height',
      'weight.value',
      'category',
    ];

    const requiredFields =
      mode === 'completed' ? completedRequiredFields : draftRequiredFields;

    requiredFields.forEach((field) => {
      let fieldValue;
      if (field === 'category') {
        fieldValue = formData.category;
      } else {
        fieldValue = field.includes('.')
          ? formData.basicDetails[field.split('.')[0]][field.split('.')[1]]
          : formData.basicDetails[field];
      }

      if (!fieldValue || fieldValue === '') {
        const capitalizedField = capitalizeFieldName(field);
        errors[field] = `${capitalizedField} is required for a ${mode === 'completed' ? 'completed' : 'draft'
          } record.`;
      }
    });

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, error]) => {
        toast.error(error, { toastId: `validation-${field}` });
      });
    }

    return Object.keys(errors).length === 0;
  };

  // Check if all required fields are filled for 'completed' status
  const checkAllRequiredFieldsFilled = () => {
    const completedRequiredFields = [
      'brand',
      'model',
      'condition',
      'quantity',
      'dimensions.length',
      'dimensions.width',
      'dimensions.height',
      'weight.value',
      'category',
    ];
    return completedRequiredFields.every((field) => {
      let fieldValue;
      if (field === 'category') {
        fieldValue = formData.category;
      } else {
        fieldValue = field.includes('.')
          ? formData.basicDetails[field.split('.')[0]][field.split('.')[1]]
          : formData.basicDetails[field];
      }
      return fieldValue && fieldValue.toString().trim() !== '';
    });
  };

  // Determine listing status based on form completion
  const determineListingStatus = () => {
    if (isEditMode && itemDetails.recordCompletionStatus === 'completed') {
      return 'completed';
    }
    return checkAllRequiredFieldsFilled() ? 'completed' : 'draft';
  };

  // Convert formData to payload for submission, including listingStatus and photographyStatus
  const convertFormDataToPayload = (recordCompletionStatus) => {
    const {
      brand,
      model,
      condition,
      quantity,
      item_description,
      dimensions,
      weight,
      shippingMethods,
      price,
      additional_info,
      bestOffer,
      autoAcceptPrice,
      autoDeclinePrice,
      acceptsReturns,
    } = formData.basicDetails;

    return {
      item_description,
      additional_info,
      condition,
      brand,
      model,
      category: formData.category,
      price,
      quantity,
      dimensions: {
        length: dimensions.length,
        width: dimensions.width,
        height: dimensions.height,
        unit: dimensions.unit || 'INCH',
      },
      weight: {
        value: weight.value,
        unit: weight.unit || 'POUND',
      },
      acceptsReturns,
      shippingMethods,
      bestOffer,
      autoAcceptPrice,
      autoDeclinePrice,
      listToChannels: formData.listToChannels,
      recordCompletionStatus,
      photographyStatus: formData.photographyStatus, // Include photographyStatus
    };
  };

  // Handle form submission (save)
  const handleSave = () => {
    const recordCompletionStatus = determineListingStatus();
    const isValid = handleFormValidation(recordCompletionStatus);

    if (isValid) {
      const submissionData = convertFormDataToPayload(recordCompletionStatus);

      if (isEditMode) {
        dispatch(updateItem({ id: productId, inventoryData: submissionData }))
          .then(() => {
            toast.success('Product updated successfully!');
            navigate('/arbie/ecom-products');
          })
          .catch(() => {
            toast.error('Failed to update product.');
          });
      } else {
        dispatch(addInventoryItem(submissionData))
          .then(() => {
            toast.success('Product created successfully!');
            navigate('/arbie/ecom-products');
          })
          .catch(() => {
            toast.error('Failed to create product.');
          });
      }
    }
  };

  // Label for save button based on listing status
  const saveButtonLabel =
    determineListingStatus() === 'draft' ? 'Save as Draft' : 'Save';

  if (loading || connectionsLoading) return <Spinner />;

  return (
    <div
      className={`container mx-auto p-6 lg:pt-4 lg:px-4 ${darkMode ? 'dark' : ''
        } relative`} // Added 'relative' for modal positioning
    >
      <ToastContainer />
      <div className="flex justify-between mb-4 items-center">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
          >
            <ArrowLeftIcon className="h-6 w-6 mr-2" />
          </button>
          <h1 className="text-2xl font-bold dark:text-gray-100">
            {pageTitle}
          </h1>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-green-600 text-sm"
          >
            {saveButtonLabel}
          </button>
          {isEditMode && (
            <>
              <button className="bg-red-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-red-600 text-sm">
                Delete
              </button>
              <button className="bg-indigo-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-indigo-600 text-sm">
                Archive
              </button>
            </>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
        <div className="lg:col-span-3">
          <ProductBasicDetails
            product={formData.basicDetails}
            setBasicDetails={handleBasicDetailsChange}
            formErrors={formErrors}
            darkMode={darkMode}
          />
        </div>

        <div className="lg:col-span-1">
          <ProductConnections
            selectedConnections={selectedConnections}
            setSelectedConnections={setSelectedConnections}
            inventoryRecord={itemDetails}
            darkMode={darkMode}
            setFormData={setFormData}
            formData={formData} // Pass formData as a prop
            isEditMode={isEditMode} // Pass isEditMode to ProductConnections
            initialListToChannels={initialListToChannels} // Pass initialListToChannels
            openModal={() => setIsModalOpen(true)} // Pass openModal function
          />
          <div className="mt-8">
            <ProductOrganization
              category={formData.category}
              setFormData={setFormData}
              onCategoryChange={handleCategoryChange}
              darkMode={darkMode}
            />
          </div>
        </div>
      </div>

      <div className="mt-0">
        <ProductMediaSection
          media={itemDetails?.images || []}
          darkMode={darkMode}
        />
      </div>

      {/* **Render Modal at Page Level** */}
      {isModalOpen && (
        <ManageConnectionsModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          connections={connections}
          selectedConnections={selectedConnections}
          setSelectedConnections={setSelectedConnections}
          darkMode={darkMode}
          formData={formData}
          setFormData={setFormData}
          isEditMode={isEditMode} // Pass isEditMode to the modal
          initialListToChannels={initialListToChannels} // Pass initialListToChannels
        />
      )}
    </div>
  );
};

export default ProductDetailsPage;
